<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent>
    <q-card class="q-dialog-plugin" style="width: 300px">
      <q-card-section class="row justify-between items-center bg-grey-2">
        <a class="text-h6">Export List Order</a>
        <q-btn flat dense icon="close" @click="onCancelClick"></q-btn>
      </q-card-section>
      <q-card-section class="q-gutter-y-sm">
        <q-select
          outlined
          label="Periode"
          class="full-width q-mt-md"
          :options="data.periode"
          v-model="data.selPeriode"
        >
        </q-select>
        <q-markup-table dense flat>
          <tr>
            <td>Mulai Periode</td>
            <td>:</td>
            <td>
              {{ moment(data.selPeriode?.dibuka).format("DD/MM/YY HH:mm") }}
            </td>
          </tr>
          <tr>
            <td>Akhir Periode</td>
            <td>:</td>
            <td>
              {{ moment(data.selPeriode?.ditutup).format("DD/MM/YY HH:mm") }}
            </td>
          </tr>
          <tr>
            <td>Pengambilan</td>
            <td>:</td>
            <td>
              {{
                moment(data.selPeriode?.tanggal_pengambilan).format(
                  "DD/MM/YY HH:mm"
                )
              }}
            </td>
          </tr>
          <tr>
            <td>Total Order</td>
            <td>:</td>
            <td>
              {{
                data.selPeriode?.summary.banyak
                  ? data.selPeriode?.summary.banyak
                  : 0
              }}
            </td>
          </tr>
          <tr>
            <td>Total Nominal</td>
            <td>:</td>
            <td>
              {{
                data.selPeriode?.summary.total_nominal
                  ? rupiah(data.selPeriode?.summary.total_nominal)
                  : "-"
              }}
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          unelevated
          class="full-width"
          color="primary"
          label="DOWNLOAD"
          :disable="!data.selPeriode"
          @click="waitThis(downloadExcel)"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { inject, onMounted, reactive, ref } from "vue";
import useBasic from "@/composables/useBasic";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  emits: [...useDialogPluginComponent.emits],

  setup() {
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
      useDialogPluginComponent();

    const {
      waitThis,
      onMounted,
      route,
      $http,
      ref,
      $q,
      loadThis,
      isLoadingData,
      moment,
      rupiah,
    } = useBasic();

    let data = reactive({
      periode: [],
      selPeriode: null,

      listOrder: [],
    });

    onMounted(async () => {
      await getPeriode();
    });

    let getPeriode = async () => {
      let resp = await $http.get("/list-po");
      for (let item of resp.data) {
        item.label = `${moment(item.dibuka).format("DD MMM YYYY")} - ${moment(
          item.ditutup
        ).format("DD MMM YYYY")}`;
      }

      resp.data = resp.data.filter((val) => {
        return moment(val.dibuka).isBefore(moment());
      });

      data.periode = resp.data;
    };

    let downloadExcel = async () => {
      let resp = await $http.get(`/list-order/${data.selPeriode.id}/ASC`);
      let order = resp.data;

      order = order.filter((val) => {
        return val.status == "ACC";
      });

      const workbook = new ExcelJS.Workbook();
      const pesanan = workbook.addWorksheet("PESANAN");

      pesanan.columns = [
        { header: "No", width: 10 },
        { header: "Nama", width: 32 },
        { header: "HP", width: 25 },
        { header: "Kelompok", width: 10 },
        { header: "Beras Qty", width: 10 },
        { header: "Harga Beras", width: 12 },
        { header: "Minyak Qty", width: 10 },
        { header: "Harga Minyak", width: 12 },
        { header: "Nominal", width: 25 },
        { header: "Order Pada", width: 25 },
      ];

      let count = 1;
      for (let item of order) {
        pesanan.addRow([
          count++,
          item.nama,
          item.hp,
          item.kelompok,
          item.beras,
          parseInt(item.beras) + parseInt(item.minyak) >= 10
            ? item.beras_agen
            : item.beras_price,
          item.minyak,
          parseInt(item.beras) + parseInt(item.minyak) >= 10
            ? item.minyak_agen
            : item.minyak_price,
          item.nominal,
          moment(item.created).format("DD MMM YYYY HH:mm"),
        ]);
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      saveAs(blob, `PO ${data.selPeriode.label}.xlsx`);
    };

    let onOKClick = () => {
      onDialogOK();
    };

    return {
      dialogRef,
      onDialogHide,
      onOKClick,
      onCancelClick: onDialogCancel,
      route,
      loadThis,
      isLoadingData,
      data,
      moment,
      waitThis,
      downloadExcel,
      rupiah,
    };
  },
};
</script>

<template>
  <q-card>
    <q-card-section
      class="bg-grey-2 no-border-radius f12"
      style="border: 1px solid black"
    >
      <div>Selamat Datang di Halaman Administrator</div>
      <q-markup-table dense flat class="bg-transparent" v-if="data.active.id">
        <tr>
          <td style="padding-left: 0 !important">PO Dibuka</td>
          <td>:</td>
          <td>
            {{ `${moment(data.active.dibuka).format("DD MMM YYYY HH:mm")}` }}
          </td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">PO Ditutup</td>
          <td>:</td>
          <td>
            {{ `${moment(data.active.ditutup).format("DD MMM YYYY HH:mm")}` }}
          </td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">Pengambilan</td>
          <td>:</td>
          <td>
            {{
              `${moment(data.active.tanggal_pengambilan).format(
                "DD MMM YYYY HH:mm"
              )}`
            }}
          </td>
        </tr>
        <tr>
          <td style="padding-left: 0 !important">Order Masuk</td>
          <td>:</td>
          <td>{{ data.total }}</td>
        </tr>
      </q-markup-table>
      <div v-else class="text-h6 text-bold">TIDAK ADA PO BERJALAN</div>
    </q-card-section>
    <q-card-section>
      <div class="column q-gutter-y-sm">
        <q-btn
          unelevated
          class="full-width"
          label="LIST ORDER"
          :disable="!data.active.id"
          color="primary"
          to="/admin/list-order"
        ></q-btn>
        <q-btn
          unelevated
          class="full-width"
          label="LIST PERIODE PO"
          color="primary"
          to="/admin/list-po"
        ></q-btn>
        <q-btn
          unelevated
          class="full-width"
          label="DOWNLOAD EXCELL"
          color="primary"
          @click="showDialogExcell"
        ></q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import useBasic from "@/composables/useBasic";
import dialogExcell from "@/components/DialogExcell";
export default {
  components: [dialogExcell],
  setup() {
    const {
      reactive,
      ref,
      onMounted,
      route,
      router,
      loadThis,
      isLoadingData,
      $http,
      moment,
      waitThis,
      $q,
    } = useBasic();

    let data = reactive({
      user: {
        nama: "John Doe",
        hp: "+62812345678",
        kelompok: "3",
      },
      active: {},
      total: 0,
    });

    onMounted(() => {
      waitThis(getData);
    });

    let getData = async () => {
      await getPeriod();
      if (data.active.id) {
        await getTotal();
      }
    };

    let getTotal = async () => {
      let resp = await $http.get(`/total-order/${data.active.id}`);
      data.total = resp.data.banyak;
    };

    let getPeriod = async () => {
      try {
        let resp = await $http.get("/active-period");
        if (resp.data.length > 0) {
          data.active = resp.data[0];
        }
      } catch (err) {
        data.active = "none";
      }
    };

    let showDialogExcell = () => {
      $q.dialog({
        component: dialogExcell,
      });
    };

    return {
      data,
      showDialogExcell,
      moment,
    };
  },
};
</script>
